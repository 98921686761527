@import '../../../styles/base.sass'


// $font-size: 
$widget-border: none
$input-box-shadow: none
$input-color-placeholder: lightgrey
$font-size: 1.1em

//@import '~react-widgets/lib/scss/react-widgets'

.input 
  display: flex
  align-items: center

.select__one
  // the buttonless version of this is currently only styled in articles.sass because it shoud likely look very different everywhere the buttonless version is used
  display: flex 
  flex-direction: column
  h6
    font-size: 1em
    margin-bottom: .8em
    color: $light-grey
  &__container 
    display: flex
    // background: red
    @include edgesnap
      flex-wrap: wrap
    &__item 
      margin-right: 1.4em
      @include edgesnap
        margin-bottom: .5em
.dropdown
  display: flex 
  align-self: flex-start
  justify-self: flex-start
  // width: 250px 
  width: 100%
  border-bottom: 1px solid $main-dark
  .rw-placeholder
    color: $light-grey
  select, img 
    margin-bottom: .2em
  select
    display: flex
    font-family: $mainfont
    -webkit-appearance: none
    border: none 
    border-radius: 0px
    font-size: 1.15em
    // font-color: $main-accent !important
    // width: 150px
    background: white
    option 
      .default
        color: green !important
      .dropdown-option
        color: red
  img 
    align-self: flex-end
    height: 20px
    width: 20px
    // background: orange
  .rw-dropdown-list
    width: 100%
    .dropdown-widget 
      width: 100% 
.half
  // width: 125px !important
  width: 100%
  select
    width: 75px !important

    
.short__text 
  display: flex
  align-items: center
  label 
    display: flex
    flex-direction: column
    // background: orange
    input 
      float: right
      // background: pink
      border: none
      border-bottom: 1px solid $main-accent
      -webkit-appearance: none 
      transition: border-color .5s ease-out
      &:required
        border-bottom: 1px solid $main-accent
        box-shadow: none
        outline: none
      &:invalid
        border-bottom: 1px solid $main-accent
  &__required
    display: flex

.checkbox-group, .radio-button-group__option
  &__image
    max-width: 200px
    width: 17%
    border-radius: 12px
    box-shadow: 0 0 4px 2px rgba(155,155,155,0.3)
    overflow: hidden
    @include tablet 
      width: 25%
      border-radius: 9px
    @include tablet-phone
      border-radius: 7px
      width: 35%



.checkbox-group
  color: $main-dark
  // text-transform: capitalize
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
  margin-bottom: .7em
  &__input
    display: flex
    align-items: center
    input[type="checkbox"]
      appearance: initial
      cursor: pointer
      width: 22px
      height: 22px
      // border: 1px solid $light-grey
      border-radius: .3em
      box-shadow: 0px 0px 3px 1px rgba(155,155,155,0.3) 
      position: relative
      margin: 0.5em 1em 0.5em 0
      &:after
        transition: all .18s linear
        color: transparent
        content: "x"
        text-align: center
        font-weight: 600
        font-size: 1.9em
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -58%)

      &:checked
        &:after
          color: $purple
    

.radio-button-group
  &__option
    display: flex
    align-items: center
    color: $main-dark
    text-transform: capitalize
    display: flex
    align-items: center
    width: 100%
    justify-content: space-between
    margin-bottom: .7em
    --radio-size: 22px
    &:hover
      // padding: .25em
      // input[type="radio"]
      //   border: 1px solid $main-accent
    input[type="radio"]
      transition: all .3s ease-in-out
      appearance: initial
      width: var(--radio-size)
      height: var(--radio-size)
      border: 2px solid $main-accent
      border-radius: 50%
      // padding: 2em
      cursor: pointer
      position: relative
      margin: 0.5em 0.5em 0.5em 0
      &:after
        transition: all .18s linear
        background: transparent
        content: " "
        position: absolute
        cursor: pointer
        left: 50%
        top: 50%
        width: 12px
        height: 12px
        border-radius: 50%
        transform: translate(-50%, -50%)

      &:checked
        &:after
          // background: #6fffe9
          background: $purple
    label
      display: flex
      align-items: center
      cursor: pointer

.date-picker
  margin-bottom: 2em
  margin-top: 1em


.dropdown, .rw-dropdown-list, .dropdown-widget
  @include tablet-phone
    height: 20px

.dropdown-widget, .rw-widget-input, .rw-widget-picker, .rw-widget-container
  @include tablet-phone
    height: 20px !important


.react-toggle--checked .react-toggle-track 
  background-color: $green !important
